@import '../variables';
.header__cart {
  display: flex;
  align-items: center;
}
.header {
  border-bottom: 1px solid $gray-line;
  padding: 40px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu {
      &__container {
        display: flex;
      }
      &__item {
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  &__logo {
    display: flex;

    img {
      margin-right: 15px;
    }

    h1 {
      color: #181818;
      font-size: 24px;
      letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      color: #7b7b7b;
    }
  }
}
